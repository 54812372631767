import { useRouter } from "next/router";
import { useRedirectPreviousPath } from "./authHooks";
import IconClose from "components/Icon/IconClose";
import {
  getSubDomain,
  isBlueBirdURL,
  isGateURL,
  isJambiURL,
  isMalukuURL,
  isMuaroJambiURL,
  isPesawaranURL,
  isSubdomainBluebird,
  isTrenggalekURL,
  useIsSubdomain,
} from "lib/host";
import { isBrowser } from "lib/ssr";
import { useEffect } from "react";
import { useGetSubdomainData } from "modules/Tenant/homeTenantApi";
import { useAuthStore } from "./authStore";
import { AuthLogo, AuthLogoMaluku } from "./AuthLogo";
import { DownloadAppBanner } from "components/Banner/DownloadAppBanner";
import Images from "next/image";
import { useSystemStore } from "components/PageHeader/systemStore";
import useTranslation from "next-translate/useTranslation";
import { getCookie, randomizeMinMax } from "lib/helper";
import { Logo } from "components/Logo";

export const AuthLayoutDesktop: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation("common");
  const quotesArrayInLang = t("quotes", {}, { returnObjects: true });
  const indexRandomized = randomizeMinMax(0, quotesArrayInLang.length);
  const theme = useSystemStore((state) => state.theme);
  const router = useRouter();
  const { previousPath } = useRedirectPreviousPath();
  const isSubdomainTenant = useIsSubdomain();
  const subDomain =
    isBrowser() && typeof window !== "undefined"
      ? getSubDomain(window.location.hostname)
      : null;
  const { tenantData, setTenantData } = useAuthStore((state) => ({
    tenantData: state.tenantData,
    setTenantData: state.setTenantData,
  }));
  // @ts-ignore
  const getSubdomainAPI = useGetSubdomainData();

  const referralCode =
    getCookie("ref_code_gk") && getCookie("ref_code_gk") !== "null"
      ? getCookie("ref_code_gk")
      : typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("referral")
      : "";

  useEffect(() => {
    // Prevent on B2C to hit API
    if (
      isSubdomainTenant &&
      // || referralCode == "trenggalekkab"
      tenantData?.image_url == null
    ) {
      getSubdomainAPI.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : // : referralCode == "trenggalekkab"
                // ? referralCode
                subDomain,
        },
        {
          onSuccess(data) {
            /// Save to zustand
            setTenantData(data["data"]);
            // @ts-ignore
            // setImageUrl(
            //   "https://s.gokampus.com/cdn-cgi/image/,quality=75/business/logo-summarecon-agung.png"
            // );
          },
        }
      );
    }
  }, [referralCode]);
  const isGate =
    typeof window !== "undefined" && isGateURL(window.location.hostname);

  // only for maluku tenant
  const isMalukuTenant =
    typeof window !== "undefined" && isMalukuURL(window.location.hostname);

  // Only for pesawaran tenant
  const isPesawaranTenant =
    typeof window !== "undefined" && isPesawaranURL(window.location.hostname);

  // Only for trenggalek tenant
  const isTrenggalekTenant =
    typeof window !== "undefined" && isTrenggalekURL(window.location.hostname);

  const isMuaroJambiTenant =
    typeof window !== "undefined" && isMuaroJambiURL(window.location.hostname);
  const isJambiKotaTenant =
    typeof window !== "undefined" && isJambiURL(window.location.hostname);

  const onClickArrow = () => {
    // If any trigger from my-class and user not logged in
    var isUsingLoginAuthorize =
      previousPath.includes("my-class") || previousPath.includes("course");

    // Kursus terbaik tentang
    var isHomeUrl = previousPath === "home";

    router.push({
      pathname: isUsingLoginAuthorize || isHomeUrl ? "/" : previousPath,
    });
  };

  const isBlueBirdTenant =
    typeof window !== "undefined" &&
    (isBlueBirdURL(window.location.hostname) ||
      isSubdomainBluebird(window.location.hostname));

  return (
    <div>
      {/* desktop view */}
      <div className="flex flex-col min-h-screen sg-max:hidden">
        {/* <DownloadAppBanner /> */}
        <div className="flex-1 grid grid-cols-[3fr,4fr] sg-max:grid-cols-1">
          {isMalukuTenant ? (
            <>
              <div className="block md:hidden">
                <>
                  {theme == "light" && (
                    <Images
                      src={"/maluku/logo2.png"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/maluku/logo-dark-ver.png"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                </>
              </div>
              <div className="hidden md:flex md:justify-start md:items-center md:p-16">
                <>
                  {theme == "light" && (
                    <Images
                      src={"/maluku/logo2.png"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/maluku/logo-dark-ver.png"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                </>
              </div>
            </>
          ) : isPesawaranTenant ? (
            <>
              <div className="block md:hidden">
                <>
                  {theme == "light" && (
                    <Images
                      src={"/pesawaran/logo.png"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/pesawaran/logo-dark.svg"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                </>
              </div>
              <div className="hidden md:flex md:justify-start md:items-center md:p-16">
                <div className="block">
                  {theme == "light" && (
                    <Images
                      src={"/pesawaran/logo.png"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/pesawaran/logo-dark.svg"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                </div>
              </div>
            </>
          ) : isTrenggalekTenant ? (
            // || referralCode == "trenggalekkab"
            <>
              <div className="block md:hidden">
                <>
                  {theme == "light" && (
                    <Images
                      src={"/trenggalek/logo.png"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/trenggalek/logo-dark.svg"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                </>
              </div>
              <div className="hidden md:flex md:justify-start md:items-center md:p-16">
                <div className="block">
                  {theme == "light" && (
                    <Images
                      src={"/trenggalek/logo.png"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/trenggalek/logo-dark.svg"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                </div>
              </div>
            </>
          ) : isMuaroJambiTenant ? (
            <>
              <div className="block md:hidden">
                <>
                  {theme == "light" && (
                    <Images
                      src={"/muaro-jambi/logo.png"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/muaro-jambi/logo-dark.svg"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                </>
              </div>
              <div className="hidden md:flex md:justify-start md:items-center md:p-16">
                <div className="block">
                  {theme == "light" && (
                    <Images
                      src={"/muaro-jambi/logo.png"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/muaro-jambi/logo-dark.svg"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                </div>
              </div>
            </>
          ) : isJambiKotaTenant ? (
            <>
              <div className="block md:hidden">
                <>
                  {theme == "light" && (
                    <Images
                      src={"/jambi/logo.png"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/jambi/logo-dark.svg"}
                      alt="app"
                      width={152}
                      height={25}
                    />
                  )}
                </>
              </div>
              <div className="hidden md:flex md:justify-start md:items-center md:p-16">
                <div className="block">
                  {theme == "light" && (
                    <Images
                      src={"/jambi/logo.png"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                  {theme == "dark" && (
                    <Images
                      src={"/jambi/logo-dark.svg"}
                      alt="app"
                      width={340}
                      height={70}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <AuthLogo className="m-64 flex" />
          )}

          <div className="bg-layout-auth-pattern relative items-center justify-center flex sg-max:hidden">
            <div className="w-[80%] m-[4%] bg-white rounded-12">
              <div
                onClick={() => onClickArrow()}
                className="absolute cursor-pointer"
                style={{
                  right: "4%",
                  top: "4%",
                }}
              >
                <IconClose color={theme === "light" ? "#000" : "#fff"} size={16} />
              </div>

              <div className="p-32 flex justify-center items-center mx-auto">
                <div className="relative flex flex-col items-center my-auto w-full">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className="hidden sg-max:flex h-screen relative flex-col items-start">
        <div className="w-full">{/* <DownloadAppBanner /> */}</div>
        <div
          className={
            isGate
              ? "p-16 w-full flex flex-col justify-between h-full"
              : isMalukuTenant || isPesawaranTenant || isTrenggalekTenant
              ? // ||
                // referralCode == "trenggalekkab"
                "flex flex-col h-full w-full p-16"
              : "p-16 w-full "
          }
        >
          {isMalukuTenant ? (
            <div className="z-50 bg-white absolute top-0 left-10 right-0 py-14">
              {theme == "light" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/maluku/logo2.png"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}

              {theme == "dark" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/maluku/logo-dark-ver.png"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}
            </div>
          ) : isPesawaranTenant ? (
            <div className="z-50 bg-white absolute top-0 left-10 right-0 py-14">
              {theme == "light" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/pesawaran/logo.png"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}

              {theme == "dark" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/pesawaran/logo-dark.svg"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}
            </div>
          ) : isTrenggalekTenant ? (
            // || referralCode == "trenggalekkab"
            <div className="z-50 bg-white absolute top-0 left-10 right-0 py-14">
              {theme == "light" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/trenggalek/logo.png"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}

              {theme == "dark" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/trenggalek/logo-dark.svg"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}
            </div>
          ) : isMuaroJambiTenant ? (
            <div className="z-50 bg-white absolute top-0 left-10 right-0 py-14">
              {theme == "light" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/muaro-jambi/logo.png"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}

              {theme == "dark" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/muaro-jambi/logo-dark.svg"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}
            </div>
          ) : isJambiKotaTenant ? (
            <div className="z-50 bg-white absolute top-0 left-10 right-0 py-14">
              {theme == "light" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/jambi/logo.png"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}

              {theme == "dark" && (
                <Images
                  // @ts-ignore
                  onClick={() => router.push("/")}
                  src={"/jambi/logo-dark.svg"}
                  alt="app"
                  width={152}
                  height={25}
                />
              )}
            </div>
          ) : (
            <AuthLogo />
          )}

          <div
            onClick={() => onClickArrow()}
            className="absolute cursor-pointer"
            style={{
              right: "4%",
              top: "4%",
            }}
          >
            <IconClose color={theme === "light" ? "#000" : "#fff"} size={16} />
          </div>

          {isMalukuTenant ? (
            <div className="h-[140%] flex">{children}</div>
          ) : isPesawaranTenant ? (
            <div className="h-[140%] flex">{children}</div>
          ) : isTrenggalekTenant ? (
            // || referralCode == "trenggalekkab"
            <div className="h-[140%] flex">{children}</div>
          ) : isMuaroJambiTenant ? (
            <div className="h-[140%] flex">{children}</div>
          ) : isJambiKotaTenant ? (
            <div className="h-[140%] flex">{children}</div>
          ) : (
            <>{children}</>
          )}

          {isBlueBirdTenant && (
            <div className="flex justify-center">
              <div>
                <p className="text-c1 font-light">Powered by</p>
                <Logo scale={1.2} useWhite={theme == "dark"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
